<template>
  <div class="referral-income">
    <div class="container-xl">
      <div class="card card-page">
        <div class="card-header d-flex">
          <div class="card-title">Правила программы лояльности</div>
          <div class="referral-balance ml-auto">
            <span class="text-muted">Бонусы в Программе лояльности Лендли</span>
            <money :money="profile.balance" :decimal="true" class="ml-3"/>
          </div>
        </div>
        <div class="card-body">
          <div class="h3">Постоянная акция «Приведи друга»</div>
          <p class="mt-3">
            Это начисление бонусов от суммы инвестиций, совершенных привлеченными Пользователем новыми инвесторами, зарегистрировавшимися на Платформе, используя ИРК Пользователя.
          </p>
          <p>
            Мы будем учитывать всех приглашенных вами инвесторов, пришедших по вашей ссылке, и начислять бонусы лояльности.
          </p>
          <p>
            После принятия
            <a href="https://cdn.lendly.ru/loyal.pdf"

               target="_blank">
              Оферты участия в Программе лояльности Лендлии
            </a>
            была сгенерирована уникальная многоразовая ссылка (индивидуальный регистрационный код), в которой содержится информация о вашей учетной записи. Одна ссылка действует бессрочно и рассчитана на неограниченное количество приглашений.
          </p>
          <div class="h3">Порядок расчета и начисления бонуса</div>
          <p>
            Бонусы начисляются по ставке <strong>0,5%</strong> от суммы всех инвестиций в выдачу займов нового инвестора в течение 6 (шести) месяцев с даты его регистрации на Платформе. Начисление осуществляется на последнее число месяца, в котором новый инвестор совершил инвестирование.
          </p>
          <div class="h3">Порядок выплаты</div>
          <p>
            Пользователь вправе воспользоваться начисленными бонусными рублями один раз в месяц, подав заявку на вывод средств. Лимит вывода – не менее 5000 рублей. Пока сумма на вашем балансе не достигнет указанного лимита вы, к сожалению, не сможете их вывести.
          </p>
          <div class="h3">Разовые акции</div>
          <p>
            Информация о проведении разовых акций будет появляться тут после их введения, а для участия в каждой разовой акции вам необходимо согласится с офертой на разовую акцию, в которой вы хотите принять участие.
          </p>
        </div>
        <div v-if="profile.accepted === false" class="card-body">
          <div class="form-group">
            <button @click="markAccepted" type="button" class="btn btn-info">
              Запросить индивидуальный код
            </button>
          </div>
          <p>
            Делая запрос на получение индивидуального кода, Вы соглашаетесь с условиями
            <a href="https://cdn.lendly.ru/loyal.pdf"
               target="_blank"
            >Согласие с офертой участия в Программе лояльности Лендли
            </a>
          </p>
        </div>
        <div v-if="profile.accepted" class="card-body">
          <data-loading :loading="profileLoading"/>
          <div class="row">
            <div class="col-12 col-md-6">
              <dl v-if="!profileLoading" class="flex-column flex-lg-row">
                <dt>Индивидуальный код</dt>
                <dd>{{ profile.referralCode }}</dd>
                <dt>Ссылка</dt>
                <dd>
                  {{ publicBaseUrl }}/auth/register-lender/?r={{ profile.referralCode }}
                </dd>
              </dl>
            </div>
            <div class="col-12 col-md-6">
              <form @input="validateWithdrawForm" @change="validateWithdrawForm"
                    @submit.stop.prevent="submitWithdrawForm">
                <div class="h6 mt-3 mt-md-0">Запросить выплату</div>
                <div class="form-group">
                  <div class="d-flex">
                    <div>
                      <input-money v-model="withdrawAmount" :currency="true" class="form-control"/>
                      <input-errors :errors="errors.withdrawAmount"/>
                      <div class="text-muted">
                        Минимальная сумма:
                        <money :money="minWithdrawSum"/>
                      </div>
                    </div>

                      <div class="ml-1">
                        <button type="submit" class="btn btn-info">Запросить</button>
                      </div>
                  </div>
                  <div>
                    Делая запрос на вывод, Вы соглашаетесь с условиями
                    <a href="https://cdn.lendly.ru/loyal.pdf"
                       target="_blank"
                    >Согласие с офертой участия в Программе лояльности Лендли
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-body font-italic">
          Если вы финансовый блогер, владелец сайта финансовой (иной профильной) специализации, если у вас есть вопросы
          или предложения по
          сотрудничеству, свяжитесь с нами по электронному адресу <a
            href="mailto:support@lendly.ru">support@lendly.ru</a> для обсуждения условий
          сотрудничества.
        </div>
        <div v-if="profile.accepted" :class="{ 'd-none': withdraws.totalCount === 0 }" class="card-body">
          <paginated-list :count="withdraws.count"
                          :total-count="withdraws.totalCount"
                          :prefix="withdraws.prefix"
                          :data-loading-lock="true"
                          @update="loadWithdrawRequests"
                          ref="withdraws"
          >
            <template v-slot:no-items>
              <div></div>
            </template>
            <div v-if="withdraws.items.length > 0">
              <div class="h6 mb-3">Запросы на выплату</div>
              <div v-for="withdraw in withdraws.items" class="row mt-2">
                <div class="col-4 col-sm-3 col-md-2">
                  <div class="small text-muted">Дата</div>
                  <date-format :date="withdraw.date"/>
                </div>
                <div class="col-4 col-md-2">
                  <div class="small text-muted">Сумма</div>
                  <money :money="withdraw.money"/>
                </div>
                <div class="col-3 col-sm-3 col-md-2">
                  <div class="small text-muted">Статус</div>
                  {{ withdrawRequestStatusLabel(withdraw.status) }}
                </div>
                <div v-if="withdrawRequestStatusIsNew(withdraw.status)" class="col-12 col-sm-2 col-md-6 mt-1 mt-sm-0">
                  <div class="text-center text-md-left">
                    <div class="small text-muted d-none d-sm-block">&nbsp;</div>
                    <button @click="cancelWithdrawForm(withdraw.uuid)" class="btn btn-sm btn-outline-info">
                      Отменить
                    </button>
                  </div>
                </div>
                <div v-else class="col-12 col-md-6 mt-1 mt-md-0">
                  <div class="small text-muted d-none d-md-block">Комментарий</div>
                  {{ withdraw.comment }}
                  <span v-if="!withdraw.comment" class="d-none d-md-block">-</span>
                </div>
              </div>
            </div>
          </paginated-list>
        </div>
        <div v-if="profile.accepted" class="card-body">
          <div class="h6 mb-3">Список приглашенных инвесторов</div>
          <paginated-list :count="referrals.count"
                          :total-count="referrals.totalCount"
                          :data-loading="referrals.loading"
                          :prefix="referrals.prefix"
                          :data-loading-lock="true"
                          @update="loadReferrals"
          >
            <div v-if="referrals.items.length > 0" class="row">
              <div class="col-4 col-sm-3 small text-muted">
                Дата регистрации
              </div>
              <div class="col-8 col-sm-5 small text-muted">
                Имя
              </div>
              <div class="col-sm-4 d-none d-sm-block small text-muted">
                Тип
              </div>
            </div>
            <div v-for="referral in referrals.items" class="row mt-2">
              <div class="col-4 col-sm-3">
                <date-format :date="referral.registerDate"/>
              </div>
              <div class="col-8 col-sm-5">
                {{ referral.name }}
              </div>
              <div class="col-8 col-sm-4 offset-4 offset-sm-0">
                {{ userTypeLabel(referral.userType) }}
              </div>
            </div>
          </paginated-list>
        </div>
        <div v-if="profile.accepted" class="card-body">
          <div class="d-flex between">
            <div class="h6 mb-3">Транзакции</div>
            <div class="referral-balance ml-auto">
          </div>
            <span class="text-muted">Баланс</span>
            <money :money="profile.balance" :decimal="true" class="ml-3"/>
          </div>
          <div class="clearfix mb-3">
            <div class="h6 float-left mt-2 mb-0">История платежей</div>
            <button class="btn btn-sm btn-outline-info float-right d-md-none"
                    type="button"
                    data-toggle="collapse"
                    data-target="#list-filter"
                    aria-expanded="false"
            >
              <i class="bi-filter"></i>
            </button>
          </div>
          <paginated-list :count="transactions.count"
                          :filter="filter"
                          :total-count="transactions.totalCount"
                          :data-loading="transactions.loading"
                          :prefix="transactions.prefix"
                          :data-loading-lock="true"
                          @update="loadTransactions"
          >
            <template v-slot:filter>
              <div class="filter-form mb-3 collapse d-md-block" id="list-filter">
                <div class="row">
                  <div class="col-12 col-md-6 col-lg-5">
                    <calendar-range
                        :initialStart="filter.dateRange.start"
                        :initialEnd="filter.dateRange.end"
                        @update="handleDateRangeUpdate"
                    />
                  </div>
                  <div class="col-12 col-md-6 col-lg-2 mt-2 mt-md-0">
                    <c-multi-select :model="filter.paymentTypes"
                                    :options="paymentTypeOptions"
                                    placeholder="Тип"
                                    @input="paymentTypeInput"/>
                  </div>
                  <div v-if="referralsOptions.length > 0" class="col-12 col-md-9 col-lg-4 mt-2 mt-lg-0">
                    <c-multi-select
                        :model="filter.referralUuids"
                        :options="referralsOptions"
                        placeholder="Пользователь"/>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 col-md-2 col-lg-2 mt-2 mt-lg-0 text-right text-lg-left d-flex">
                    <button type="submit" class="btn btn-outline-success">
                      Фильтровать
                    </button>
                    <button type="submit" @click="clearFilter" class="btn btn-outline-danger ml-3">
                      Сбросить
                    </button>
                    <button @click="exportTransactions" class="btn btn-outline-info ml-3">
                      Экспортировать
                    </button>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="transactions.items.length > 0" class="row d-none d-sm-flex">
              <div class="col-sm-3 col-md-2 small text-muted">
                Дата
              </div>
              <div class="col-sm-2 small text-muted">
                Сумма вознаграждения
              </div>
              <div class="col-sm-2 col-md-1 small text-muted">
                Тип
              </div>
              <div class="col-sm-5 col-md-4 col-lg-3 small text-muted">
                Пояснение
              </div>
              <div class="col-md-3 col-lg-4 small text-muted d-none d-md-block">
                Пользователь
              </div>
            </div>
            <div v-for="transaction in transactions.items" class="row mt-2">
              <div class="col-4 col-sm-3 col-md-2">
                <date-format :date="transaction.date"/>
              </div>
              <div class="col-6 col-sm-2">
                <money :money="transaction.money" :decimal="true"/>
              </div>
              <div class="col-2 col-md-1">
                <i v-if="isCredit(transaction.paymentType)" class="bi-arrow-left text-success" title="Входящий"></i>
                <i v-if="isDebit(transaction.paymentType)" class="bi-arrow-right" title="Исходящий"></i>
              </div>
              <div class="col-12 col-sm-4 col-lg-3">
                {{ transactionTypePurpose(transaction.transactionType) }}
              </div>
              <div class="col-12 col-md-3 col-lg-4">
                <div v-if="transaction.referral">
                  <span class="text-muted d-sm-none">{{ transaction.referral.name }}</span>
                  <span class="d-none d-sm-inline">{{ transaction.referral.name }}</span>
                </div>
                <div v-else>-</div>
              </div>
            </div>
          </paginated-list>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {
  CMultiSelect,
  DataCollection,
  DataLoading,
  DateFormat,
  InputErrors,
  InputMoney,
  Money,
  PaginatedList,
  Processing,
  PublicLink
} from "@/components/common";
import {appConfig} from "@/configs";
import {Money as ApiMoney, PaymentType, WithdrawRequestStatus} from "@/utils/api-client";
import {createReferralManager, createReferralWithdrawManager} from "@/utils/di";
import transactionTypePurposes from "./data/transactionTypePurposes";
import userTypeLabels from "./data/userTypeLabels";
import withdrawRequestStatusLabels from "./data/withdrawRequestStatusLabels";
import {DatePicker} from "v-calendar";
import paymentTypeLabels from "@/components/dashboard/components/transactions/data/paymentTypeLabels";
import * as XLSX from "xlsx";
import moment from "moment/moment";
import CalendarRange from "@/components/common/components/CalendarRange.vue";

export default {
  name: "ReferralIncome",
  components: {
    CalendarRange,
    DatePicker,
    CMultiSelect,
    DataLoading,
    DateFormat,
    InputErrors,
    InputMoney,
    Money,
    PaginatedList,
    Processing,
    PublicLink
  },
  data() {
    return {
      profileLoading: false,
      profile: {},
      referrals: new DataCollection("r_"),
      transactions: new DataCollection("t_"),
      withdraws: new DataCollection("w_"),
      agreement: false,
      withdrawAmount: null,
      processing: false,
      errors: {},
      filter: {
        dateRange: {
          start: null,
          end: null
        },
        paymentTypes: [],
        referralUuids: [],
      },
      referralsOptions: []
    };
  },
  mounted() {
    this.loadProfile();
    this.loadReferrals()
  },
  computed: {
    publicBaseUrl() {
      return appConfig.publicBaseUrl;
    },
    minWithdrawSum() {
      if (this.profile.balance) {
        return new ApiMoney(appConfig.minReferralWithdraw * 100, this.profile.balance.currencyCode);
      }
    },
    paymentTypeOptions() {
      return Object.keys(paymentTypeLabels).map((value) => ({value, text: paymentTypeLabels[value]}));
    }
  },
  methods: {
    async loadProfile() {
      this.profileLoading = true;
      try {
        const manager = await createReferralManager();
        Object.assign(this.profile, await manager.getProfile());
      } finally {
        this.profileLoading = false;
      }
    },
    paymentTypeInput(paymentTypes) {
      const types = this.getPaymentTransactionTypes(paymentTypes);
      this.filter.transactionTypes = this.filter.transactionTypes.filter((type) => types.indexOf(type) > -1);
    },
    handleDateRangeUpdate(dates) {
      this.filter.dateRange.start = dates.start;
      this.filter.dateRange.end = dates.end;
    },
    clearFilter() {
      this.filter.dateRange.start = null;
      this.filter.dateRange.end = null;
      this.filter.referralUuids = [];
      this.filter.paymentTypes = [];
    },
    async markAccepted() {
      this.processing = true;
      try {
        const manager = await createReferralManager();
        await manager.markAccepted();
      } finally {
        this.processing = false;
      }
      await this.loadProfile();
    },
    async loadWithdrawRequests(page, perPage) {
      this.withdraws.startLoading();
      try {
        const manager = await createReferralWithdrawManager();
        const collection = await manager.getList(page, perPage);
        this.withdraws.setCollection(collection);
      } finally {
        this.withdraws.finishLoading();
      }
    },
    withdrawRequestStatusLabel(status) {
      return withdrawRequestStatusLabels[status] || status;
    },
    withdrawRequestStatusIsNew(status) {
      return status === WithdrawRequestStatus.NEW;
    },
    validateWithdrawForm() {
      const errors = {};
      if (this.withdrawAmount < this.minWithdrawSum.amount / 100) {
        errors.withdrawAmount = ['Сумма вывода меньше минимальной суммы'];
      } else if (this.withdrawAmount > this.profile.balance.amount / 100) {
        errors.withdrawAmount = ['Сумма вывода больше доступной суммы'];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async submitWithdrawForm() {
      if (this.validateWithdrawForm()) {
        this.processing = true;
        try {
          const manager = await createReferralWithdrawManager();
          await manager.create(new ApiMoney(this.withdrawAmount * 100, this.profile.balance.currencyCode));
          this.withdrawAmount = null;
        } finally {
          this.processing = false;
        }
        await this.$refs.withdraws.update();
      }
    },
    cancelWithdrawForm(uuid) {
      this.$confirm("Вы хотите отменить запрос на вывод средств?", async () => {
        this.processing = true;
        try {
          const manager = await createReferralWithdrawManager();
          await manager.cancel(uuid);
        } finally {
          this.processing = false;
        }
        await this.$refs.withdraws.update();
      });
    },
    async loadReferrals(page, perPage) {
      this.referrals.startLoading();
      try {
        const manager = await createReferralManager();
        const collection = await manager.getUserList(page, perPage);
        this.referrals.setCollection(collection);
      } finally {
        this.referralsOptions = this.referrals.items.map(referral => ({
          value: referral.uuid,
          text: referral.name
        }));
        this.referrals.finishLoading();
      }
    },
    formatDate(date) {
      return `${date.year}-${date.month.padStart(2, '0')}-${date.day.padStart(2, '0')}`;
    },

    async exportTransactions() {
      const { dateRange, paymentTypes, referralUuids } = this.filter;
      const manager = await createReferralManager();
      const perPage = 50;

      let page = 1;
      let allTransactions = [];
      let hasMoreData = true;

      const startDate = this.formatDate(dateRange.start);
      const endDate = this.formatDate(dateRange.end);

      while (hasMoreData) {
        const collection = await manager.getTransactionList(
            {
              dateFrom: startDate,
              dateTo: endDate,
              paymentTypes,
              referralUuids,
            },
            page,
            perPage
        );
        allTransactions = allTransactions.concat(collection.items);

        if (allTransactions.length === collection.totalCount) {
          hasMoreData = false;
        } else {
          page++;
        }
      }

      const data = allTransactions.map((transaction) => {
        return {
          Дата: transaction.date && moment(transaction.date).format("DD.MM.YYYY"),
          Сумма: transaction.money.amount / 100,
          Тип: transaction.paymentType,
          Пояснение: this.transactionTypePurpose(transaction.transactionType),
          Пользователь: transaction.referral ? transaction.referral.name : '',
        };
      });

      const worksheet = XLSX.utils.json_to_sheet(data);

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Транзакции');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const dataBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });

      const downloadLink = document.createElement('a');
      const url = URL.createObjectURL(dataBlob);
      downloadLink.href = url;
      downloadLink.download = 'реферальные_транзакции.xlsx';
      downloadLink.click();

      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 100);
    },

    async loadTransactions(page, perPage) {
      this.transactions.startLoading();
      try {
        const { dateRange, paymentTypes, referralUuids } = this.filter;
        const manager = await createReferralManager();

        const startDate = this.formatDate(dateRange.start);
        const endDate = this.formatDate(dateRange.end);

        const collection = await manager.getTransactionList(
            { dateFrom: startDate, dateTo: endDate, paymentTypes, referralUuids },
            page,
            perPage
        );
        this.transactions.setCollection(collection);
      } finally {
        this.transactions.finishLoading();
      }
    },
    isCredit(paymentType) {
      return paymentType === PaymentType.CREDIT;
    },
    isDebit(paymentType) {
      return paymentType === PaymentType.DEBIT;
    },
    userTypeLabel(userType) {
      return userTypeLabels[userType] || userType;
    },
    transactionTypePurpose(transactionType) {
      return transactionTypePurposes[transactionType] || transactionType;
    }
  }
};
</script>

<style lang="scss">
.referral-income {
  .card {
    .card-header {
      .referral-balance {
        font-size: 1.25rem;
      }
    }

    .card-body {
      min-height: 8rem;
    }

    dl {
      dt, dd {
        width: 100%;
        flex: 0 0 100%;
        max-width: none;
        margin-bottom: 0.5rem;

        @media screen and (min-width: 992px) {
          width: 50%;
          flex: 0 0 50%;
        }
      }
    }
  }
}
</style>
